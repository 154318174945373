/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.performance[data-v-013b8fe6] {
  background-color: #f3f3f3;
}
.performance[data-v-013b8fe6]  .selection-holder {
  width: 40rem !important;
}
.performance-wrapper[data-v-013b8fe6] {
  position: relative;
  padding: 2rem 2rem 10rem;
  width: 100%;
  overflow-x: scroll;
}
.performance-wrapper .section-top[data-v-013b8fe6] {
  height: 4rem;
  justify-content: space-between;
  margin-left: auto;
  margin-bottom: 2rem;
}
.performance-wrapper .section-top .manager-heading[data-v-013b8fe6] {
  padding-left: 1rem;
}
.performance-wrapper .section-top .date-select select[data-v-013b8fe6] {
  height: 3rem;
  width: 12rem;
  border-color: #ccc;
  border-radius: 0.5rem;
  cursor: pointer;
}
.performance-wrapper .section-top .alt-calc-toggle[data-v-013b8fe6] {
  position: relative;
  border-bottom: 1.5px solid darkgray;
  /* Sliding underline */
}
.performance-wrapper .section-top .alt-calc-toggle .tab-btn[data-v-013b8fe6] {
  padding: 0 10px;
  font-weight: bold;
  color: #e93f6f;
  height: 100%;
  transition: background-color 0.3s ease;
}
.performance-wrapper .section-top .alt-calc-toggle .tab-btn[data-v-013b8fe6]:hover {
  cursor: pointer;
  background-color: #fefefe;
}
.performance-wrapper .section-top .alt-calc-toggle .tab-btn[data-v-013b8fe6]:not(:last-child) {
  padding-right: 20px;
}
.performance-wrapper .section-top .alt-calc-toggle #underline[data-v-013b8fe6] {
  position: absolute;
  bottom: 0;
  height: 2.5px;
  background-color: black;
  transition: left 0.3s ease, width 0.3s ease;
}
.performance-wrapper .table-wrapper[data-v-013b8fe6] {
  position: relative;
  align-items: flex-start;
}
.performance-wrapper .table-wrapper .manager-list-wrapper[data-v-013b8fe6] {
  flex: 0 0 300px;
  padding-bottom: 1rem;
}
.performance-wrapper .table-wrapper .table-inputs-wrapper[data-v-013b8fe6] {
  flex: 1;
  padding-bottom: 1rem;
}
.performance-wrapper .save-slide-out[data-v-013b8fe6] {
  width: 85%;
  position: fixed;
  transform: translateY(8rem);
  bottom: 10px;
  transition: transform 0.3s ease-in;
  right: 0;
}
.performance-wrapper .save-slide-out.show-save[data-v-013b8fe6] {
  transform: translateY(0rem);
}
.performance-wrapper .save-slide-out .slide-out-inner[data-v-013b8fe6] {
  width: 360px;
  margin: 0 auto;
  padding: 1.25rem;
  background: white;
  border-radius: 1rem 1rem 0 0;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.17);
}
.performance-wrapper .save-slide-out .slide-out-inner .btn[data-v-013b8fe6]:first-of-type {
  margin-right: 1rem;
}
.no-data[data-v-013b8fe6] {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.centerY[data-v-013b8fe6] {
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
}
.mt-3[data-v-013b8fe6] {
  margin-top: 2rem;
}
.manager-header[data-v-013b8fe6] {
  margin: 2rem 0;
}